<!--start dialog template -->
<template>
  <v-row justify="center">
    <v-dialog :value="dialog" persistent max-width="900px" scrollable>
      <v-card>
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title>
            <span class="headline">{{ title }} University</span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text style="height:200px">
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row>
                <v-col cols="12" sm="12" md="4" lg="4">
                  <v-text-field
                    label="Add Name"
                    v-model="formData.name"
                    required
                    :rules="isValid.name"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="2" lg="2">
                  <v-text-field
                    label="Acronym"
                    v-model="formData.acronym"
                    required
                    :rules="isValid.name"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="2" lg="2">
                  <v-text-field
                    label="Category"
                    hint="University,College"
                    v-model="formData.category"
                    required
                    :rules="isValid.name"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="4" lg="4">
                  <v-text-field
                    label="Former Names"
                    v-model="formData.former_names"
                    required
                    :rules="isValid.name"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12" md="2" lg="2">
                  <v-text-field
                    label="Reg#"
                    v-model="formData.reg_no"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="3" lg="3">
                  <v-text-field
                    label="Region"
                    v-model="formData.region"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="3">
                  <v-text-field
                    label="Status"
                    v-model="formData.status"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="2" lg="2">
                  <v-text-field
                    label="Founded in "
                    v-model="formData.founded"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="2" lg="2">
                  <v-text-field
                    label="Ownership "
                    v-model="formData.ownership"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <DialogButtons
            :close="close"
            :save="add"
            :loading="loading"
            :valid="valid"
            :title="title"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<!--end dialog-->

<script>
export default {
  props: ["closeDialog", "dialog", "isEditing", "formData", "save", "loading"],
  name: "OptionDialog",
  data: () => ({
    valid: true,
    isValid: {
      name: [
        (v) => !!v || " Name required",
        /// (v) => /.+[^#<>:;,?""*|/]+/.test(v) || 'Invalid Character',
      ],
    },
  }),
  methods: {
    add() {
      let data = { ...this.formData };
      data.code = data.code
        ? data.code
        : data.name
            .trim()
            .split(" ")
            .join("_")
            .toUpperCase();

      this.save(data);
    },

    close() {
      this.closeDialog();
    },
  },
  computed: {
    title() {
      return this.formData.id ? "Update" : "Create";
    },
  },
  mounted() {},
};
</script>
